import React from 'react';
import PropTypes from 'prop-types';

import LandingHero from '../../../components/Hero/Landing';
import usePageBody from '../../../hooks/usePageBody';

const HomePageTemplate = ( { headings, pageBody, preview, getAsset } ) => {
	const { renderPageBody, renderPageBodyPreview } = usePageBody();

	return (
		<>
			<header>
				<LandingHero headings={ headings }/>
			</header>
			<main id="landing-content" style={{ marginTop: '5rem' }}>
				{ pageBody
				&& pageBody.map( ( component, i ) => !preview
					? renderPageBody( { ...component, i } )
					: renderPageBodyPreview( { ...component, i }, getAsset ) ) }
			</main>
		</>
	);
};

HomePageTemplate.propTypes = {
	headings: PropTypes.array,
	pageBody: PropTypes.array,
	preview: PropTypes.bool,
	getAsset: PropTypes.func
};

export default HomePageTemplate;
